<template>
    <!-- 学会通知 -->
    <div class="notice">
        <topHeader ind="2"></topHeader>
        <div class="notice_cont">
            <PageContent :title="'学会通知'" :total="total" :list="noticeList" @getArticleList="getArticleList"/>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import PageContent from '../comm/pageContent.vue'
    import axios from '../http/axios'
    export default {
        data(){
            return{
                noticeList:[],
                total:null,
            }
        },
        components:{
            PageContent
        },
        mounted(){
            this.getArticleList()
        },
        methods:{
            getArticleList(page=1,page_number=5){
                axios.post('article/list',{page,page_number,category_name:'notice'}).then(res=>{
                    this.noticeList = res.data.data
                    this.total = res.data.total
                })
            }
        }
    }
</script>

<style scoped lang='less'>

</style>